import React, { useState } from "react";
import styled from "styled-components";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosCheckmark,
  IoMdOpen,
} from "react-icons/io";

import { useModal } from "../../components/useModal";

import Image from "../../components/image";

import { Loader, Success } from "../../components/feedback";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../../components/util";
import {
  AuthorCard,
  SmallResourceCard,
  Breadcrumb,
  Modal,
} from "../../components/site";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const WebinarCornerPic = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 1000px;
  z-index: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

const Form = styled(Box)`
  z-index: 2;
  padding: 30px;
  text-align: center;
  background-color: ${(props) => props.theme.colours.white};
  border-radius: ${(props) => props.theme.styles.borderRadius};
  box-shadow: ${(props) => props.theme.styles.shadow};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 20px;
  }

  h2 {
    color: ${(props) => props.theme.colours.dark} !important;
  }

  form {
    margin-bottom: 0;
  }
`;

// const CardRow = styled(Row)`
//   width: calc(100% + 30px);
//   @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
//     width: 100%;
//   }
// `;

const WebinarSeries = (props) => {
  let [success, setForm] = useState(false);
  let [loading, setFormLoading] = useState(false);

  const [newsletter, setNewsletter] = useState(false);

  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  const handleCheckboxChange = (event) => {
    setNewsletter(event.target.checked);
  };

  const handleSubmit = () => {
    setFormLoading(true);
    setTimeout(function() {
      setForm(true);
      setFormLoading(false);
    }, 2000);
  };

  const contentfulData = useStaticQuery(graphql`
    query {
      allContentfulResource(
        sort: { order: DESC, fields: publishedDate }
        filter: {
          resourceTitle: {
            in: [
              "How compliant is your payroll? Questions to ask yourself (or your payroll provider)."
              "What is Payroll Compliance?"
              "Understanding New Zealand’s payroll compliance nightmare"
            ]
          }
        }
      ) {
        edges {
          node {
            resourceImage {
              gatsbyImageData(width: 75, layout: FIXED, quality: 100)
            }
            resourceTitle
            resourceType
            resourceTopic
            url
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title="Holidays Act Deep Dive Webinar Series | PayHero Webinar"
        pathname={props.location.pathname}
      />
      <Breadcrumb
        parentPage="CA ANZ"
        parentPageLink="/ca"
        currentPage="Holidays Act Deep Dive - Provision, Portion & Payment"
      />
      <Container className="primary" css={{ paddingTop: "15px" }}>
        <Wrapper stackGap={60}>
          <Row stackGap={80} alignCenter>
            <Box stackGap={40} size={60} css={{ zIndex: "2" }} centerOnMobile>
              <h4 className="badge -lg -dark">On Demand Webinar Series</h4>
              <h1 className="-fontLarge">
                Holidays Act Deep Dive - Provision, Portion & Payment
              </h1>
              <Image
                filename="CAANZ_PayHero_Horiz.png"
                alt="Holidays Act Deep Dive - Provision, Portion & Payment"
                maxWidth={350}
                width="60%"
                centerOnMobile
              />
            </Box>
            <Form size={40}>
              <Box stackGap={30}>
                <iframe
                  name="stopRedirect"
                  id="stopRedirect"
                  css={{ display: "none" }}
                  title="FlexiTime | Submit Form"
                ></iframe>
                <Loader show={loading} />
                <h2 css={{ marginTop: "10px" }}>
                  Register for On Demand Webinar Series
                </h2>
                {!success && (
                  <>
                    <form
                      className={`${loading ? "-isLoading" : ""} -smallStack`}
                      data-drip-embedded-form="61682001"
                      action="https://www.getdrip.com/forms/61682001/submissions"
                      target="stopRedirect"
                      method="POST"
                      onSubmit={handleSubmit}
                    >
                      <input
                        placeholder="First Name"
                        type="text"
                        name="fields[first_name]"
                        required
                      />
                      <input
                        placeholder="Last Name"
                        type="text"
                        name="fields[last_name]"
                        required
                      />
                      <input
                        placeholder="Email Address"
                        type="email"
                        name="fields[email]"
                        required
                      />
                      <input
                        placeholder="Company Name"
                        type="text"
                        name="fields[company_name]"
                        required
                      />
                      <input
                        placeholder="Contact Number"
                        type="text"
                        name="fields[contact_number]"
                        required
                      />
                      <div className="select-wrapper">
                        <select
                          name="fields[region]"
                          aria-label="Select Region"
                          defaultValue=""
                          required
                        >
                          <option value="" disabled>
                            Select Region...
                          </option>
                          <option value="Auckland">Auckland</option>
                          <option value="Bay of Plenty">Bay of Plenty</option>
                          <option value="Canterbury">Canterbury</option>
                          <option value="Chatam Islands">Chatam Islands</option>
                          <option value="Gisborne">Gisborne</option>
                          <option value="Hawke's Bay">Hawke's Bay</option>
                          <option value="Manawatu - Whanganui">
                            Manawatu - Whanganui
                          </option>
                          <option value="Marlborough">Marlborough</option>
                          <option value="Nelson">Nelson</option>
                          <option value="Northland">Northland</option>
                          <option value="Otago">Otago</option>
                          <option value="Southland">Southland</option>
                          <option value="Taranaki">Taranaki</option>
                          <option value="Tasman">Tasman</option>
                          <option value="Waikato">Waikato</option>
                          <option value="Wellington">Wellington</option>
                          <option value="West Coast">West Coast</option>
                        </select>
                        <IoIosArrowDown className="dropdown-arrow -dark" />
                      </div>
                      <input
                        type="text"
                        name="fields[newsletter_signup]"
                        value={newsletter}
                        hidden
                      />
                      <div className="checkbox-wrapper">
                        <label>
                          <div>
                            <input
                              aria-label="Sign up to our newsletter | PayHero"
                              type="checkbox"
                              onChange={handleCheckboxChange}
                            />
                            <IoIosCheckmark className="checkmark" />
                          </div>
                          <p
                            css={{
                              marginBottom: "0",
                              color: "#333 !important",
                              top: "-2px",
                              position: "relative",
                            }}
                          >
                            Sign up to our newsletter
                          </p>
                        </label>
                      </div>
                      <button className="darkBlue">Register</button>
                    </form>
                  </>
                )}
                <Success
                  show={success}
                  title="Successfully Registered"
                  message="Thanks! You're registered for this on demand webinar series. We'll be in touch when it's ready."
                  centerAlign
                />
              </Box>
            </Form>
          </Row>
        </Wrapper>
        <WebinarCornerPic>
          <Image
            filename="WebinarSeriesCornerPic.png"
            alt="PayHero Webinar Series"
          />
        </WebinarCornerPic>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={80} mobileReverse>
            <Box size={30} stackGap={45}>
              <Box stackGap={25}>
                <h4 className="-fontBold">Hosted by:</h4>
                <AuthorCard author="Rob" />
              </Box>
              <hr />
              <Box stackGap={25}>
                <h4 className="-fontBold">Related Resources:</h4>
                {contentfulData.allContentfulResource.edges.map((edge, i) => {
                  return (
                    <SmallResourceCard
                      image={
                        edge.node.resourceImage &&
                        edge.node.resourceImage.gatsbyImageData
                      }
                      url={edge.node.url}
                      type={edge.node.resourceType}
                      title={edge.node.resourceTitle}
                      key={i}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box size={70} stackGap={60}>
              <Box stackGap={25}>
                <h2>Holidays Act Deep Dive - Provision, Portion & Payment</h2>
                <h4>
                  Join us for a series of short pre-recorded webinars that
                  expand on our recent{" "}
                  <Link to="/ca/untangling-holidays-act">
                    Untangling Holidays Act Compliance webinar
                  </Link>{" "}
                  with Chartered Accountants ANZ.
                </h4>
                <h4>
                  In the original webinar we explained the main Holidays Act
                  compliance issues in New Zealand, the scale of the problem and
                  how to account for leave.
                </h4>
                <h4>
                  We also introduced the three key steps to providing an
                  employee’s entitlement to, and payment, for annual holidays
                  (or, as we like to call them, the 3 P’s):
                </h4>
                <ul>
                  <li>
                    <h4>
                      Step 1: <b>Provision</b> of the entitlement
                    </h4>
                  </li>
                  <li>
                    <h4>
                      Step 2: Determining the <b>portion</b> of entitlement
                      taken
                    </h4>
                  </li>
                  <li>
                    <h4>
                      Step 3: <b>Payment</b> for annual holidays taken
                    </h4>
                  </li>
                </ul>
                <h4>
                  This new webinar series looks at each of those steps in more
                  detail, with a range of real life examples. The webinars are
                  approximately 15 minutes each and delivered as on-demand
                  videos, for you to watch at your leisure.
                </h4>
                <h4>
                  Register now to expand your knowledge of payroll and holidays
                  and move forward confident that you can advise your clients
                  how to pay employees right.
                </h4>
              </Box>
              <hr />
              <Box stackGap={25}>
                <h4 className="-fontBold">About PayHero</h4>
                <h4>
                  PayHero is perfect for Kiwi companies that want stress free
                  and compliant online payroll. Especially those with part time
                  and mobile workforces, who face unique challenges tracking
                  employee time accurately and calculating the right holiday
                  entitlements for their staff.
                </h4>
                <h4>
                  PayHero streamlines the employee timesheet process with
                  online, mobile, GPS or clock in/out options delivering
                  accurate employee hours straight into payroll.
                </h4>
                <h4 className="-fontBold">
                  <Link className="link-arrow" to="/ca">
                    Find out more <IoIosArrowForward />
                  </Link>
                </h4>
              </Box>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container className="-textCenter secondary">
        <Wrapper stackGap={80}>
          <Box stackGap={40}>
            <h2>
              Compliant payroll you and your clients can trust
              <br />
              <span className="-fontLight">
                Find out more about PayHero or our partner programme.
              </span>
            </h2>
            <FlexButtons justify="center">
              <Button
                className="primary -lg gtm-cta"
                onClick={toggleModal}
                atag
              >
                Request a Call Back
              </Button>
              <Button
                className="grey -lg"
                to="https://partners.flexitime.works"
                atag
                externalLink
              >
                Become a Partner <IoMdOpen css={{ top: "2px" }} />
              </Button>
            </FlexButtons>
          </Box>
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default WebinarSeries;
